<template>
  <el-dialog
    :title="$l('elevator.callElevator','呼梯设置')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1200px"
    top="5vh"
    @close="dialogReset">
    <div v-loading="contentLoading" class="body" style="min-height: 55vh;">
      <div class="vm-separate">
        <div>
          <el-button type="primary" @click="$refs.elevatorSelect.open()">
            {{$t("elevatorUser.selectLift")}}
          </el-button>
          <elevator-select
            ref="elevatorSelect"
            :multi-selection="false"
            @select="onElevatorSelect"></elevator-select>
          <div style="border: 1px solid #65676c;height: 50vh;overflow-y: scroll;margin: 5px 5px 0 0;padding: 3px;">
            <div v-for="item in elevatorList" :key="item.id" style="margin-top: 10px">
              <div>{{$l('elevatorNetApply.elevatorName','电梯名称')}}：{{item.name}}</div>
              <div style="margin-top: 5px">{{$l('elevatorNetApply.regCode','设备代码')}}：{{item.regNo}}</div>
              <div style="margin-top: 5px">{{$l('elevatorNetApply.dtuCode','DTU编号')}}：{{item.dtuCode}}</div>
              <el-table
                ref="multipleTable"
                :data="item.elevatorFloors"
                :multi-selection="true"
                border
                style="width: 100%;margin-top: 5px"
                @selection-change="handleMultiSelectChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <table-column prop="value" :label="$l('elevator.actualFloor','实际楼层')" align="center">
                </table-column>
                <table-column prop="displayValue" :label="$l('elevator.showFloor','显示楼层')" align="center">
                </table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div>
          <el-button type="success" style=";margin: 0 0 0 5px" @click="$refs.userSelect.open()">
            {{$t("elevatorUser.selectUser")}}
          </el-button>
          <user-select
            ref="userSelect"
            :multi-selection="true"
            @multi-select="onMultiUserSelect"
            @select="onUserSelect"></user-select>
          <div style="border: 1px solid #65676c;height: 50vh;overflow-y: scroll;margin: 5px 0 0 5px;padding: 3px;">
            <el-table
              :data="userList"
              border
              style="margin-top: 5px">
              <table-column prop="username" :label="$t('user.username')" align="center"></table-column>
              <table-column prop="name" :label="$t('user.name')" align="center"></table-column>
              <table-column prop="phone" :label="$t('user.phone')" align="center"></table-column>
            </el-table>
            <div style="clear:both"></div>
          </div>
        </div>
      </div>
      <div class="vm-separate" style="margin-top: 50px">
        <div>
          <span>{{$l('elevator.authorizationTime','授权时间')}}：</span>
          <el-date-picker
            v-model="dateRange"
            type="datetimerange"
            :start-placeholder="$t('common.beginTime')"
            :end-placeholder="$t('common.endTime')"
            value-format="yyyy-MM-dd HH:mm:ss"
            :placeholder="$t('yearCheck.date')">
          </el-date-picker>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import ElevatorSelect from "../elevator/ElevatorSelect.vue";
  import UserSelect from "../user/UserSelect.vue";

  export default {
    components: {ElevatorSelect, UserSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        elevatorList: [],
        userList: [],
        multiSelect: [],
        floorMappings: [],
        dateRange: [],
        floors: [],
        startTime: "",
        endTime: "",
        status: "",
        now: "",
        afterNow: "",
      };
    },
    methods: {
      open(id) {
        this.currentTime();
        this.dialogVisible = true;
        if (id > 0) {
          this.getData(id);
        }else {
          this.dateRange = [this.now, this.afterNow];
        }
      },
      currentTime() {
        var date = new Date();
        var year = date.getFullYear(); //月份从0~11，所以加一
        let month = date.getMonth();
        var dateArr = [
          date.getMonth() + 1,
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
        ];
        //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
        for (var i = 0; i < dateArr.length; i++) {
          if (dateArr[i] >= 1 && dateArr[i] <= 9) {
            dateArr[i] = "0" + dateArr[i];
          }
        }
        var strDate = year + "-" + dateArr[0] + "-" + dateArr[1] + " " + dateArr[2] + ":" +
          dateArr[3] + ":" + dateArr[4];

        var endDate = year+1 + "-" + dateArr[0] + "-" + dateArr[1] + " " + dateArr[2] + ":" +
          dateArr[3] + ":" + dateArr[4];

        this.now = strDate;
        this.afterNow = endDate;
      },
      getData(id) {
        this.$http.get("elevatorUsers/getById/" + id).then(res => {
          this.elevatorList = res.data.elevatorList;
          this.userList = res.data.userList;
          this.floors = res.data.displayFloors.split(",");
          this.getRow(this.elevatorList[0].elevatorFloors);
          this.dateRange = [res.data.startTime ? res.data.startTime : "", res.data.endTime ? res.data.endTime : ""];
        });
      },
      getRow(elevatorFloors) {
        this.$nextTick(() => {
          this.floors.forEach(row => {
            this.$refs.multipleTable[0].toggleRowSelection(elevatorFloors.find(item => {
              return row == item.displayValue;
            }), true);
          });
        });
      },
      dialogReset() {
        this.userList = [];
        this.elevatorList = [];
        this.floorMappings = [];
        this.dateRange = [];
      },
      onElevatorSelect(row) {
        this.elevatorList = [];
        this.$api.getById("elevators", row.id).then(res => {
          this.elevatorList.push(res.data);
        });
      },
      onUserSelect(row) {
        this.userList = [];
        this.userList.push(row);
      },
      onMultiUserSelect(rows) {
        for (let row of rows) {
          let flag = true;
          for (let item of this.userList) {
            if (item.id === row.id) {
              flag = false;
            }
          }
          if (flag) {
            this.userList.push(row);
          }
        }
      },
      handleMultiSelectChange(val) {
        this.multiSelect = val;
        this.floorMappings = [];
        this.multiSelect.forEach(item => {
          this.floorMappings.push(item.displayValue);
        });
      },
      handleSubmit() {
        let userIds = [];
        if (this.elevatorList.length === 0) {
          this.$message.error(this.$t("elevatorUser.elevatorNotSelected"));
          return;
        }
        if (this.floorMappings.length === 0) {
          this.$message.error("未选择显示楼层");
          return;
        }
        if (this.userList.length === 0) {
          this.$message.error(this.$t("elevatorUser.userNotSelected"));
          return;
        }
        for (let item of this.userList) {
          userIds.push(item.id);
        }
        this.submitLoading = true;
        let params = {
          elevatorID: this.elevatorList[0].id,
          userIds: userIds,
          displayFloors: this.floorMappings.join(","),
          startTime: this.dateRange[0],
          endTime: this.dateRange[1],
        };
        this.$http.post("elevatorUsers", params).then(res => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success");
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
